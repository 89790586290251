<template>
  <div class="user">
    <el-form label-position="left" ref="address" label-width="90px" :rules="rules" :model="address">
      <el-form-item label="收货人" prop="reveiveName">
        <el-input v-model="address.reveiveName" placeholder="请输入收货人姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="mobile">
        <el-input v-model="address.mobile" placeholder="请输入手机号码"></el-input>
      </el-form-item>
      <el-form-item label="所在地区" prop="cityId">
        <el-row>
          <!-- 省 -->
          <el-col :span="8">
            <el-select v-model="address.provinceId" style="margin-right: 10px" @change="provinceChange">
              <el-option v-for="province in provinceList" :key="province.id" :label="province.areaName"
                :value="province.id" />
            </el-select>
          </el-col>
          <!-- 市 -->
          <el-col :span="8">
            <el-select v-model="address.cityId" style="margin-right: 10px" @change="cityChange">
              <el-option v-for="city in cityList" :key="city.id" :label="city.areaName" :value="city.id" />
            </el-select>
          </el-col>
          <!-- 区 -->
          <el-col :span="8">
            <el-select v-model="address.countyId" @change="sectionChange">
              <el-option v-for="section in sectionList" :key="section.id" :label="section.areaName" :value="section.id" />
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="address.address" placeholder="请输入详细地址"></el-input>
      </el-form-item>
      <el-form-item label="">
        <span class="btn_choose">
          <img v-if="address.isDefault == 1 && typeOne" src="@/assets/img/textmall/moren_z.png" @click="change" alt="" />
          <img v-else-if="address.isDefault == 1 && typeTwo" src="@/assets/img/textmall/moren.png" @click="change"
            alt="" />
          <img v-else-if="address.isDefault == 1 && typeThree" src="@/assets/img/textmall/moren_g.png" @click="change"
            alt="" />
          <img v-else-if="address.isDefault == 1 && typeFour" src="@/assets/img/textmall/check_big.png" @click="change"
            alt="" />
          <div v-else class="feimoren" @click="change"></div>
          <!-- :class=" ? 'text_avtice' : ''" -->
          <span class="text" :class="{
            text_avtice1: address.isDefault == 1 && typeOne,
            text_avtice2: address.isDefault == 1 && typeTwo,
            text_avtice3: address.isDefault == 1 && typeThree,
            text_avtice4: address.isDefault == 1 && typeFour,
          }">设为默认地址</span>
        </span>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button class="submit cancel" @click="cancel">取消</el-button>
      <el-button class="submit" :class="{
        submit1: typeOne,
        submit2: typeTwo,
        submit3: typeThree,
        submit4: typeFour,
      }" type="primary" @click="save">保存收货地址</el-button>
    </div>
  </div>
</template>
<script>
import { pca, pcaa } from "area-data"; // 城市数据
import PhoneChange from "@/components/mine/phoneChange.vue";
import { getInfo, getDomainToken, getDomain } from "@/api/cookies";
import { getAreaListByParentId } from "@/api/mykecheng";
import address from "@/assets/js/address.js";
export default {
  components: { PhoneChange },
  props: {
    addressForm: {
      type: Object,
    },
  },
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请确认您的新密码"));
      } else if (value !== this.form.passwords) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      userInfo: null,
      vmcity: [], // 地址
      pca: pca,
      pcaa: pcaa,
      name: "",
      names: "",
      activeName: "second",
      addressDialog: false,
      Domainjwt: {
        Domainjwt: getDomainToken(),
      },
      address: {
        provinceId: "",
        cityId: "",
        countyId: "",
        isDefault: 1,
        mobile: "",
        reveiveName: "",
        address: "",
      },
      imageUrl: "",
      user: "",
      tabIndex: 1,
      // 全部订单
      b: 1,
      captcha: "",
      ruleForm: {},
      formList: [],
      cityList: [],
      sectionList: [],
      provinceList: [],
      detail: {
        img: "",
        nickName: "",
      },
      form: {
        password: "",
        passwords: "",
        imgCode: "",
      },

      rules: {
        reveiveName: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入合法手机号",
            trigger: "blur",
          },
        ],
        cityId: [
          { required: true, message: "省市不能为空", trigger: "change" },
        ],
        address: [
          { required: true, message: "详细地址不能为空", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入您现在的登录密码",
            trigger: "blur",
          },
          { min: 6, message: "密码长度不得小于6位数", trigger: "blur" },
        ],
        passwords: [
          { required: true, message: "请输入您的新密码", trigger: "blur" },
          { min: 6, message: "密码长度不得小于6位数", trigger: "blur" },
        ],
        passwordss: [
          { required: true, validator: validatePass2, trigger: "blur" },
          { min: 6, message: "密码长度不得小于6位数", trigger: "blur" },
        ],
        imgCode: [
          { required: true, message: "请输入右边验证码", trigger: "blur" },
        ],
      },
      type: {
        sjh: "sjh",
        qq: "qq",
        wx: "wx",
      },
      title: "修改绑定",
      titles: "已绑定",
      /* 修改绑定手机号 */
      dialogVisible: false,
      dialogVisibles: false,
      fileList: [
        {
          name: "默认头像.jpeg",
          url: "https://staticfile.xlcwx.com/avatar/icon1.png",
        },
      ],
      imgVal: 99,
      imgList: [
        "https://staticfile.xlcwx.com/avatar/icon0.png",
        "https://staticfile.xlcwx.com/avatar/icon1.png",
        "https://staticfile.xlcwx.com/avatar/icon2.png",
        "https://staticfile.xlcwx.com/avatar/icon3.png",
        "https://staticfile.xlcwx.com/avatar/icon4.png",
        "https://staticfile.xlcwx.com/avatar/icon5.png",
        "https://staticfile.xlcwx.com/avatar/icon6.png",
        "https://staticfile.xlcwx.com/avatar/icon7.png",
      ],
      // class判断
      typeOne: false,
      typeTwo: false,
      typeThree: false,
      typeFour: false,
      cityVal: undefined,
      countyVal: undefined,
      addForm: "",
    };
  },
  watch: {
    cityVal: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && oldVal) {
          this.address.cityId = "";
          this.address.countyId = "";
        }
      },
      deep: true,
    },
    countyVal: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && oldVal) {
          this.address.countyId = "";
        }
      },
      deep: true,
    },
  },
  async created() {
    this.addForm = this.addressForm.address;
    this.address = this.addressForm;
    this.userInfo = await getInfo();
    this.getAreaListByParentId();
    if (this.address.provinceId && this.address.provinceId !== "") {
      this.provinceChange(this.address.provinceId);
    }
    if (this.address.cityId && this.address.cityId !== "") {
      this.cityChange(this.address.cityId);
    }
    // this.provinceList = address
    if (!this.userInfo) {
      return;
    }
    if (this.userInfo.mobile) {
      this.title = "修改绑定";
      this.titles = "已绑定";
    } else {
      this.title = "立即绑定";
      this.titles = "未绑定";
    }
    // 动态class判定
    /**
     * entrantsType=2   =>  招商加盟
     * entrantsType=3   =>  聚学U
     * entrantsType=1   =>  名跃教育
     * entrantsType=4   =>  大客户
     */
    const res = getDomain();
    if (res.entrantsType === 2) {
      this.typeOne = true;
      this.typeTwo = false;
      this.typeThree = false;
      this.typeFour = false;
    } else if (res.entrantsType === 3) {
      this.typeOne = false;
      this.typeTwo = true;
      this.typeThree = false;
      this.typeFour = false;
    } else if (res.entrantsType === 5) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeThree = true;
      this.typeFour = false;
    } else if (res.entrantsType === 1) {
      this.typeOne = false;
      this.typeTwo = false;
      this.typeThree = false;
      this.typeFour = true;
    }

    // 调用验证码接口方法

    // 调用基本资料接口方法
  },
  methods: {
    // 固定图片选择
    imgClack(wx) {
      this.imgVal = wx;
    },
    change(wx) {

      if (this.address.isDefault == 0 || this.address.isDefault == undefined) {
        // this.address.isDefault = 1
        this.$set(this.address, "isDefault", 1);
      } else if (this.address.isDefault == 1) {
        // this.address.isDefault = 0
        this.$set(this.address, "isDefault", 0);
      }
      // this.address.isDefault = Number(!this.address.isDefault);
      // this.$set(this.address, "isDefault", Number(!this.address.isDefault));
    },
    async getAreaListByParentId() {
      const res = await getAreaListByParentId({
        parentId: 0,
        level: 1,
      });
      if (res.code == 0) {
        this.provinceList = res.msg;
      }
    },
    async provinceChange(val) {
      this.cityVal = val;
      this.cityList = [];
      const params = {
        parentId: val,
        level: 2,
      };
      const res = await getAreaListByParentId(params);
      if (res.code == 0) {
        this.cityList = res.msg;
      }
    },
    async cityChange(val) {
      this.countyVal = val;
      this.sectionList = [];
      const params = {
        parentId: val,
        level: 3,
      };
      const res = await getAreaListByParentId(params);
      if (res.code == 0) {
        this.sectionList = res.msg;
      }
    },
    save(val) {
      this.$refs.address.validate((valid) => {
        if (valid) {
          this.$emit("save", this.address);
        }
      });
    },
    sectionChange(val) {
      this.address.countyId = val;
      // this.$set(this.address,"countyId",val)
    },
    editAddress() {
      this.addressDialog = true;
    },
    cancel() {
      this.address.address = this.addForm;
      this.$set(this.address, "isDefault", 0);
      this.$emit("cancelClick");
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item__label {
  font-size: 16px;

  color: #333333;
}

.btn_choose {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.user {
  width: 100%;
  padding: 0 23px;
  margin: 0 auto;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;

  .submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 126px;
    height: 42px;
    border: none;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    font-size: 16px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff !important;
    letter-spacing: 1px !important;
  }

  .submit1 {
    background: #ff5d51 !important;
  }

  .submit2 {
    background: #00ab8c !important;
  }

  .submit3 {
    background: linear-gradient(90deg, #0270e8 0%, #21b4f5 100%) !important;
  }

  .submit4 {
    background: #1061ff !important;
  }

  .cancel {
    color: #717680 !important;
    background-color: #fff !important;
    border: 1px solid #717680 !important;
    margin-right: 21px;
  }
}

.el-form-item {
  height: 34px;
  margin-bottom: 20px;

  img {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .feimoren {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    background: #ffffff;
    opacity: 1;
    border-radius: 50%;
    border: 1px solid #999999;
    margin-right: 10px;
  }

  .text {
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333;
  }

  .text_avtice1 {
    color: #ff5d51;
  }

  .text_avtice2 {
    color: #00ab8c;
  }

  .text_avtice3 {
    color: #006de7;
  }

  .text_avtice4 {
    color: #1061ff;
  }

  &:nth-child(4) {
    margin-bottom: 10px;
  }

  &:nth-child(5) {
    height: 22px;
    margin-bottom: 40px;
  }
}

.address {
  .addressAdd {
    margin-top: 9px;

    .addBtn {
      width: 110px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      border: 1px solid #00ab8c;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #00ab8c;
    }
  }

  .addressBox {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin: 26px auto 0;
    padding-top: 26px;
    padding-left: 23px;
    width: 852px;
    height: 110px;
    background: skyblue; // #ffffff;;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;

    .addressBox_left {
      flex: 1;

      .title {
        position: relative;
        font-size: 16px;
        height: 16px;
        padding-top: 0;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;

        span {
          position: absolute;
          top: 0;
          display: inline-block;
          height: 16px;
          line-height: 16px;

          &:nth-child(2) {
            left: 214px;
          }
        }
      }
    }

    .addressBox_right {
      padding-top: 39px;

      span {
        height: 22px;
        margin-right: 30px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}

.area-selected-trigger {
  width: 300px;
  height: 40px;
  line-height: 20px;
}

/deep/.el-input__inner {
  width: 100%;
  height: 34px !important;
  color: #333;
  padding: 0 10px;
}

/deep/.el-form-item__error {
  padding-top: 0;
}

.choose {
  border: 2px solid rgba(31, 150, 241, 1);
}

.chooseNot {
  border: 2px solid #fff;
}

.tips {
  width: 100%;
  height: auto;
  color: rgba(123, 129, 150, 1);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.file-upload {
  width: 100%;
  height: 50px;
  overflow: hidden;
  display: inline-block;
  border-radius: 4px;
  font-size: 12px;
  color: #ff4027;
  text-align: center;
  line-height: 26px;
  margin: 10px 0 auto auto;
}

.file-upload-input {
  background-color: transparent;
  width: auto;
  height: 50px;
  cursor: pointer;
}

.basic {
  width: 100%;
  height: 710px;
  background: #ffffff;

  // 缩宽
  .basics {
    width: 90%;
    height: 100%;
    margin: auto;

    // 标题
    p {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #27323f;
    }

    // 跳转盒子
    .basicsName {
      width: 271px;
      height: 40px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .basicsNames {
        width: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .basicsNamesTop {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          cursor: pointer;
          color: #999999;
        }

        .basicsNamesTops {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }

        .basicsNamesCen {
          width: 24px;
          height: 2px;
          margin-top: 14px;
          background: #ef0d0d;
          border-radius: 1px;
        }
      }
    }

    // 跳转内容 b = 1 修改信息 b = 2 修改信息密码
    .basicsCent {
      width: 100%;
      height: auto;
      border-top: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      // 左侧
      .basicsCentLeft {
        width: 556px;
        height: 100%;
        margin: 0 auto;
        margin-top: 42px;

        .el-form {
          .colleges-keep {
            width: 170px;
            height: 36px;
            margin-top: 40px;
            margin-left: 100px;
            text-align: center;
            cursor: pointer;
          }
        }
      }

      // 右侧
      .basicsCentRight {
        width: 390px;
        height: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        // 图片
        img {
          width: 140px;
          height: 140px;
        }

        // 上传按钮
        .filet {
          width: 110px;
          height: 36px;
          margin-top: 20px;
          background: #ffd4b0;
          margin-bottom: 14px;
          border-radius: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff4027;
          cursor: pointer;
          border: 1px solid #ffd4b0;
        }

        // 灰色提示字体
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a8abbe;
        }
      }
    }

    // 跳转内容 b = 3 账号绑定
    .basicsCenst {
      width: 100%;
      height: auto;
      border-top: 1px solid #eeeeee;

      // 绑定盒子
      .basicsCents {
        width: 940px;
        height: 90px;
        background: #f5f5f5;
        border-radius: 4px;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        // 缩宽
        .basicsCentss {
          width: 880px;
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          // 左侧
          .bdLeft {
            width: auto;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            // 图片
            img {
              width: 50px;
              height: 50px;
            }

            // 字
            p {
              line-height: 50px;
              margin-left: 20px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
          }

          // 解除绑定按钮
          .bdRight {
            width: 120px;
            height: 42px;
            background: #ffffff;
            border: 1px solid #ff4027;
            border-radius: 21px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff4027;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.avatar-uploader-sheji img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
  margin: 0 auto;
}

/deep/ .el-upload {
  display: table;
  margin: 0 auto;
}

.mantil {
  height: 104px;
  line-height: 104px;
  font-size: 24px;
  font-family: Microsoft YaHei-Bold;
  font-weight: bold;
  color: #333333;
  text-align: left;
}

// /deep/ .
</style>
